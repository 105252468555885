// src/components/Login/Login.js
import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../styles/Login.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('http://lm-electronics.io/auth/login', {
        email,
        password,
      });
      localStorage.setItem('token', response.data.token);
      
      // Fetch user information to check the email
      const userResponse = await axiosInstance.get('/api/user', {
        headers: {
          'Authorization': `Bearer ${response.data.token}`
        }
      });
      const userEmail = userResponse.data.email;

      // Check if the user email is one of the specified emails
      if (userEmail === 'maite.dricot@gmail.com' || userEmail === 'lionel@lm-electronics.io') {
        navigate('/irrigation-control'); // Redirect to irrigation control page
      } else {
        navigate('/dashboard'); // Redirect to dashboard
      }

      onLogin(); // Update authentication state
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - LM Electronics</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
