import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import '../../styles/IrrigationControl.css';
import WeatherForecast from '../WeatherForecast/WeatherForecast'; // Import the WeatherForecast component

const IrrigationControl = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [greenhouseData, setGreenhouseData] = useState({});
  const [gardenData, setGardenData] = useState({});
  const [mainPumpStatus, setMainPumpStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false); // Flag to track refresh status
  const intervalRef = useRef(null); // Ref to store the interval ID

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get('/api/user', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setCurrentUser(response.data);
      } catch (err) {
        setError('Failed to fetch user data');
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (currentUser && (currentUser.email === 'lionel@lm-electronics.io' || currentUser.email === 'maite.dricot@gmail.com')) {
      const fetchDataWithInterval = () => {
        fetchSensorData('Greenhouse', setGreenhouseData).then(() => {
          fetchSensorData('Garden', setGardenData).then(() => {
            intervalRef.current = setTimeout(fetchDataWithInterval, 5000);
          });
        });
      };

      fetchDataWithInterval();

      // Cleanup function to clear the interval
      return () => {
        if (intervalRef.current) {
          clearTimeout(intervalRef.current);
        }
      };
    }
  }, [currentUser]);

  const fetchSensorData = async (zone, setData) => {
    if (isRefreshing) return;

    setIsRefreshing(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/irrigation/status?zone=${zone}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(response.data);
      setLastUpdate(response.data.timestamp); // Update the last update time
      if (zone === 'Greenhouse') {
        setMainPumpStatus(response.data.mainPumpStatus); // Set main pump status from Greenhouse data
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message === 'You have issued too many tokens without logging out and your account might have been temporarly disabled.') {
        setError('Main pump status cannot be retrieved at the moment.');
      } else {
        setError(`Failed to fetch sensor data for ${zone}`);
      }
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
      setIsRefreshing(false);
    }
  };

  const toggleRelay = async (zone, data, setData) => {
    const newStatus = !data.relayStatus;
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post('/api/irrigation/control', { action: newStatus ? 'ON' : 'OFF', ID: zone }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(prevData => ({
        ...prevData,
        relayStatus: newStatus
      }));
    } catch (err) {
      setError(`Failed to toggle relay for ${zone}`);
    }
  };

  const toggleMainPump = async () => {
    try {
      const token = localStorage.getItem('token');
      const newStatus = !mainPumpStatus;
      await axiosInstance.post('/api/main-pump/control', { state: newStatus, ID: 'Greenhouse' }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setMainPumpStatus(newStatus);
    } catch (err) {
      setError('Failed to toggle main pump');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser || (currentUser.email !== 'lionel@lm-electronics.io' && currentUser.email !== 'maite.dricot@gmail.com')) {
    return null;
  }

  const renderZone = (zone, data, setData) => (
    <div className="irrigation-control">
      <h1>{zone} Irrigation Control</h1>
      <div className="sensor-data">
        <div className="sensor-item"><p><strong>Localisation:</strong> {data.ID}</p></div>
        <div className="sensor-item"><p><strong>Soil Moisture:</strong> {data.soilMoisture}%</p></div>
        <div className="sensor-item"><p><strong>Temperature:</strong> {data.temperature}°C</p></div>
        <div className="sensor-item"><p><strong>Flow Rate:</strong> {data.flowRate} L/min</p></div>
        {zone !== 'Greenhouse' && (
          <div className="sensor-item"><p><strong>Rain Status:</strong> {data.isRaining ? 'Raining' : 'Not Raining'}</p></div>
        )}
        <div className="sensor-item"><p><strong>Relay Status:</strong> {data.relayStatus ? 'ON' : 'OFF'}</p></div>
        <div className="sensor-item"><p><strong>Last Update:</strong> {lastUpdate}</p></div> {/* Display the last update time */}
      </div>
      <div className="control-buttons">
        <button onClick={() => toggleRelay(zone, data, setData)}>{data.relayStatus ? 'Turn OFF Relay' : 'Turn ON Relay'}</button>
      </div>
    </div>
  );

  return (
    <div>
      <div className="main-pump-control">
        <h1>Main Pump Control</h1>
        {mainPumpStatus === "Unavailable" ? (
          <button disabled style={{ backgroundColor: 'grey', color: 'white' }}>Main Pump Status Unavailable</button>
        ) : (
          <button onClick={toggleMainPump}>{mainPumpStatus ? 'Turn OFF Main Pump' : 'Turn ON Main Pump'}</button>
        )}
        {error && <p>{error}</p>}
      </div>
      {renderZone('Greenhouse', greenhouseData, setGreenhouseData)}
      {renderZone('Garden', gardenData, setGardenData)}
      <WeatherForecast /> {/* Include the WeatherForecast component */}
    </div>
  );
};

export default IrrigationControl;
