import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import './PskReporterViewer.css';

const PskReporterViewer = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hiddenColumns, setHiddenColumns] = useState([
        'id', 
        'receiver_locator', 
        'receiver_region', 
        'receiver_dxcc', 
        'sender_eqsl_auth_guar',
        'is_sender',
        'is_receiver'
    ]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [filterType, setFilterType] = useState('');

    const fetchReports = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('You must be logged in to view reports.');
            }
            const response = await axiosInstance.get('http://lm-electronics.io/api/psk-reports', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setReports(response.data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    const columns = useMemo(
        () => [
            { Header: 'ID', accessor: 'id' },
            { Header: 'Receiver Callsign', accessor: 'receiver_callsign' },
            { Header: 'Receiver Locator', accessor: 'receiver_locator' },
            { Header: 'Sender Callsign', accessor: 'sender_callsign' },
            { Header: 'Sender Locator', accessor: 'sender_locator' },
            { 
                Header: 'Frequency', 
                accessor: 'frequency',
                Cell: ({ value }) => (value / 1000).toFixed(3) // Divide by 1000 and format to 3 decimal places
            },
            { 
                Header: 'HAM Band', 
                accessor: 'ham_band' 
            },
            { 
                Header: 'Date/Time', 
                accessor: 'flow_start_seconds', 
                Cell: ({ value }) => new Date(value * 1000).toLocaleString() 
            },
            { Header: 'Mode', accessor: 'mode' },
            { Header: 'Is Sender', accessor: 'is_sender', Cell: ({ value }) => (value ? 'Yes' : 'No') },
            { Header: 'Is Receiver', accessor: 'is_receiver', Cell: ({ value }) => (value ? 'Yes' : 'No') },
            { Header: 'Sender Region', accessor: 'sender_region' },
            { Header: 'Sender DXCC', accessor: 'sender_dxcc' },
            { Header: 'Sender DXCC Code', accessor: 'sender_dxcc_code' },
            { Header: 'Sender DXCC Locator', accessor: 'sender_dxcc_locator' },
            { Header: 'Receiver Region', accessor: 'receiver_region' },
            { Header: 'Receiver DXCC', accessor: 'receiver_dxcc' },
            { Header: 'Sender Lotw Upload', accessor: 'sender_lotw_upload' },
            { Header: 'Sender Eqsl Auth Guar', accessor: 'sender_eqsl_auth_guar' },
            { Header: 'SNR', accessor: 'snr' },
            { Header: 'QSO ID', accessor: 'qso_id' }, // Add the QSO ID column
        ],
        []
    );

    const filteredReports = useMemo(() => {
        if (filterType === 'worked_dxcc') {
            return reports.filter(report => report.worked_dxcc === false);
        } else if (filterType === 'worked_dxcc_same_band') {
            return reports.filter(report => report.worked_dxcc_same_band === false);
        } else if (filterType === 'both') {
            return reports.filter(report => report.worked_dxcc === false || report.worked_dxcc_same_band === false);
        }
        return reports;
    }, [reports, filterType]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter: setTableGlobalFilter,
        allColumns,
        toggleHideColumn,
    } = useTable(
        {
            columns,
            data: filteredReports,
            initialState: { 
                hiddenColumns,
                sortBy: [
                    {
                        id: 'flow_start_seconds',
                        desc: true,
                    },
                ],
            }, // Set hidden columns initially
            globalFilter
        },
        useGlobalFilter,
        useSortBy
    );

    useEffect(() => {
        setTableGlobalFilter(globalFilter);
    }, [globalFilter, setTableGlobalFilter]);

    const handleToggleColumn = (columnId) => {
        const isHidden = hiddenColumns.includes(columnId);
        const newHiddenColumns = isHidden
            ? hiddenColumns.filter(id => id !== columnId)
            : [...hiddenColumns, columnId];

        setHiddenColumns(newHiddenColumns);
        toggleHideColumn(columnId, !isHidden);
    };

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const refreshButtonStyles = {
        padding: '10px 20px',
        margin: '10px 0',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px'
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="psk-reporter-viewer">
            <h1>PSK Reporter Reports (JO20)</h1>
            <button style={refreshButtonStyles} onClick={fetchReports}>Refresh</button>
            <input
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search all columns"
                style={{ marginBottom: '10px', padding: '8px', fontSize: '16px' }}
            />
            <div className="filter-form">
                <label>
                    Filter:
                    <select value={filterType} onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="worked_dxcc">Worked DXCC (False)</option>
                        <option value="worked_dxcc_same_band">Worked DXCC Same Band (False)</option>
                        <option value="both">Both Conditions</option>
                    </select>
                </label>
            </div>
            <div className="column-toggle">
                {allColumns.map(column => (
                    <label key={column.id}>
                        <input
                            type="checkbox"
                            checked={!hiddenColumns.includes(column.id)}
                            onChange={() => handleToggleColumn(column.id)}
                        />
                        {column.Header}
                    </label>
                ))}
            </div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        const backgroundColor = row.original.worked_dxcc === false
                            ? '#4B0082' // Dark purple
                            : row.original.worked_dxcc_same_band === false
                            ? '#9370DB' // Light purple
                            : 'white';
                        const fontStyle = row.original.worked_dxcc === false
                            ? { color: 'white', fontWeight: 'bold' }
                            : {};
                        return (
                            <tr
                                {...row.getRowProps()}
                                style={{
                                    backgroundColor,
                                    textDecoration: row.original.worked_same_band ? 'line-through' : 'none',
                                    ...fontStyle
                                }}
                                key={row.id}
                            >
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PskReporterViewer;
