// src/components/Dashboard/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Propagation from '../Propagation/Propagation';
import DXCluster from '../DXCluster/DXCluster';
import axiosInstance from '../../axiosInstance';
import '../../styles/Dashboard.css';

const Dashboard = () => {
  const [username, setUsername] = useState('User');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('/api/user');
        setUsername(response.data.email); // Assuming 'email' is the username
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user:', error);
        setError('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>{`Dashboard - ${username}`}</title>
      </Helmet>
      <h2>Welcome to the Dashboard, {username}</h2>
      <div className="dashboard-content">
        <div className="dashboard-section">
          <Propagation />
        </div>
        <div className="dashboard-section">
          <iframe 
            src="https://www.hamradiotimeline.com/timeline/dxw_timeline_1_1.php" 
            title="Ham Radio Timeline"
            width="100%" 
            height="600px" 
            style={{ border: 'none' }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
