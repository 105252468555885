// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://lm-electronics.io', // Your API base URL
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 error: token expired or unauthorized
      localStorage.removeItem('token');
      window.location.href = '/login'; // Redirect to login page
      return Promise.reject({ message: 'Session expired. Please log in again.' });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
