import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import './CallsignsMonitoring.css';

const CallsignsMonitoring = () => {
    const [callsigns, setCallsigns] = useState([]);
    const [newCallsign, setNewCallsign] = useState('');
    const [qsos, setQsos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('token'));

    // Fetch monitored callsigns
    const fetchCallsigns = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://lm-electronics.io/api/monitored_callsigns', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCallsigns(response.data.callsigns);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [token]);

    // Fetch QSOs for monitored callsigns
    const fetchQsos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://lm-electronics.io/api/monitored_qsos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setQsos(response.data.qsos || []); // Ensure qsos is an array
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        fetchCallsigns();
    }, [fetchCallsigns]);

    useEffect(() => {
        if (callsigns.length > 0) {
            fetchQsos();
        }
    }, [callsigns, fetchQsos]);

    // Handle form submission to add a new callsign
    const handleAddCallsign = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post('http://lm-electronics.io/api/monitored_callsigns', {
                callsign: newCallsign
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setNewCallsign('');
            fetchCallsigns();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Table columns
    const columns = React.useMemo(
        () => [
            { Header: 'Monitored Callsign', accessor: 'monitored_callsign' },
            { Header: 'Call', accessor: 'call' },
            { Header: 'Band', accessor: 'band' },
            { Header: 'Frequency', accessor: 'freq' },
            { Header: 'Mode', accessor: 'mode' },
            { Header: 'Latitude', accessor: 'latitude' },
            { Header: 'Longitude', accessor: 'longitude' },
            { Header: 'Date', accessor: 'date' }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: qsos,
            initialState: {
                sortBy: [
                    {
                        id: 'date',
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useSortBy
    );

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="callsign-monitoring">
            <h1>Monitored Callsigns</h1>
            <ul>
                {callsigns.map((callsign, index) => (
                    <li key={index}>{callsign}</li>
                ))}
            </ul>
            <form onSubmit={handleAddCallsign}>
                <input
                    type="text"
                    value={newCallsign}
                    onChange={(e) => setNewCallsign(e.target.value)}
                    placeholder="Add new callsign"
                />
                <button type="submit">Add Callsign</button>
            </form>

            <h2>ClubLog QSOs</h2>
            <input
                value={state.globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search QSOs"
                style={{ marginBottom: '10px', padding: '8px', fontSize: '16px' }}
            />
            <button onClick={fetchQsos} className="refresh-button">Refresh</button>
            <table {...getTableProps()} className="qso-table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CallsignsMonitoring;
