import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Propagation.css';

const Propagation = () => {
    const [propagationData, setPropagationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('http://lm-electronics.io/api/propagation');
                setPropagationData(response.data);
            } catch (err) {
                setError('Failed to fetch propagation data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getConditionColor = (condition) => {
        switch (condition.toLowerCase()) {
            case 'excellent':
                return '#d4edda'; // Light green
            case 'good':
                return '#c3e6cb'; // Chartreuse
            case 'fair':
                return '#fff3cd'; // Light yellow
            case 'poor':
                return '#f8d7da'; // Light red
            case 'band closed':
                return '#f5c6cb'; // Light pink
            default:
                return '#ffffff'; // White
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="parent-container">
            <div className="propagation-container">
                <h1>Propagation Report</h1>
                {propagationData && (
                    <>
                        <div className="general-info">
                            <p><strong>Source:</strong> {propagationData.source}</p>
                            <p><strong>Updated:</strong> {propagationData.updated}</p>
                            <p><strong>Solar Flux:</strong> {propagationData.solarflux}</p>
                            <p><strong>A-Index:</strong> {propagationData.aindex}</p>
                            <p><strong>K-Index:</strong> {propagationData.kindex}</p>
                            <p><strong>X-Ray:</strong> {propagationData.xray}</p>
                            <p><strong>Sunspots:</strong> {propagationData.sunspots}</p>
                            <p><strong>Helium Line:</strong> {propagationData.heliumline}</p>
                            <p><strong>Proton Flux:</strong> {propagationData.protonflux}</p>
                            <p><strong>Electron Flux:</strong> {propagationData.electronflux}</p>
                            <p><strong>Aurora:</strong> {propagationData.aurora}</p>
                            <p><strong>Normalization:</strong> {propagationData.normalization}</p>
                            <p><strong>Latitude Degree:</strong> {propagationData.latdegree}</p>
                            <p><strong>Solar Wind:</strong> {propagationData.solarwind}</p>
                            <p><strong>Magnetic Field:</strong> {propagationData.magneticfield}</p>
                            <p><strong>Geomagnetic Field:</strong> {propagationData.geomagfield}</p>
                            <p><strong>Signal Noise:</strong> {propagationData.signalnoise}</p>
                            <p><strong>MUF:</strong> {propagationData.muf}</p>
                        </div>
                        <div className="conditions-section">
                            <h2>Calculated Conditions</h2>
                            <div className="conditions-grid">
                                {propagationData.calculatedconditions.map((band, index) => (
                                    <div
                                        key={index}
                                        className="condition-item"
                                        style={{ backgroundColor: getConditionColor(band.condition) }}
                                    >
                                        <strong>{band.name} ({band.time}):</strong> {band.condition}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="conditions-section">
                            <h2>Calculated VHF Conditions</h2>
                            <div className="conditions-grid">
                                {propagationData.calculatedvhfconditions.map((phenomenon, index) => (
                                    <div
                                        key={index}
                                        className="condition-item"
                                        style={{ backgroundColor: getConditionColor(phenomenon.condition) }}
                                    >
                                        <strong>{phenomenon.name} ({phenomenon.location}):</strong> {phenomenon.condition}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Propagation;
