import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloudIcon from '@mui/icons-material/Cloud';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadIcon from '@mui/icons-material/Upload';
import RadioIcon from '@mui/icons-material/Radio';
import MonitorIcon from '@mui/icons-material/Monitor'; // Import the Monitor icon
import SettingsIcon from '@mui/icons-material/Settings'; // Import the Settings icon
import '../styles/SideNav.css';

const SideNav = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get('/api/user', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setUserEmail(response.data.email);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const hamMenuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Propagation', icon: <CloudIcon />, path: '/propagation' },
    { text: 'PSK Reporter (JO20)', icon: <ListAltIcon />, path: '/pskreporter' },
    { text: 'PSK Reporter (WorldWide)', icon: <ListAltIcon />, path: '/all-pskreporter' },
    { text: 'Upload ADI File', icon: <UploadIcon />, path: '/upload-adi' },
    { text: 'QSO Viewer', icon: <RadioIcon />, path: '/qsos' },
    { text: 'Callsign Monitoring', icon: <MonitorIcon />, path: '/callsign-monitoring' }, // Add Callsign Monitoring
    { text: 'User Settings', icon: <SettingsIcon />, path: '/user-settings' }, // Add User Settings
  ];

  const gardenMenuItems = [
    { text: 'Irrigation Control', icon: <DashboardIcon />, path: '/irrigation-control' },
    { text: 'Sensor Data', icon: <CloudIcon />, path: '/sensor-data' },
  ];

  const menuItems = userEmail === 'lionel@lm-electronics.io' 
    ? [...hamMenuItems, ...gardenMenuItems] 
    : userEmail === 'maite.dricot@gmail.com' ?
     [...gardenMenuItems] : hamMenuItems;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ paper: 'side-nav-paper' }}
      sx={{ width: '20%', flexShrink: 0 }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem button component={Link} to={item.path} key={index}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideNav;
