import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance';
import './AdiFileUploader.css'; // Add this line to import your CSS

const AdiFileUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file.size > 16 * 1024 * 1024) { // 16 megabytes
            setError('File size exceeds the maximum limit of 16MB.');
            setSelectedFile(null);
        } else {
            setError('');
            setSelectedFile(file);
        }
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            setUploadStatus('No file selected.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        const token = localStorage.getItem('token');
        try {
            const response = await axiosInstance.post('http://lm-electronics.io/api/upload-adi', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            setUploadStatus(`File uploaded successfully: ${response.data.message}`);
        } catch (error) {
            setUploadStatus(`File upload failed: ${error.message}`);
        }
    };

    return (
        <div className="adi-file-uploader">
            <h1>Upload ADI File</h1>
            <input type="file" accept=".adi" onChange={handleFileChange} />
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <button onClick={handleFileUpload}>Upload</button>
            {uploadProgress > 0 && (
                <div>
                    <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
                </div>
            )}
            {uploadStatus && <div>{uploadStatus}</div>}
        </div>
    );
};

export default AdiFileUploader;
