import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import './DXCluster.css';

const DXCluster = () => {
  const [spots, setSpots] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSpots = async () => {
      try {
        const response = await axiosInstance.get('http://www.dxsummit.fi/api/v1/spots');
        setSpots(response.data);
      } catch (error) {
        setError('Failed to fetch DX spots');
      }
    };

    fetchSpots();
  }, []);

  const columnHelper = createColumnHelper();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('de_call', {
        header: 'Spotter',
      }),
      columnHelper.accessor('dx_call', {
        header: 'DX Station',
      }),
      columnHelper.accessor('dx_country', {
        header: 'Country',
      }),
      columnHelper.accessor('frequency', {
        header: 'Frequency (kHz)',
      }),
      columnHelper.accessor('time', {
        header: 'Time',
        cell: info => new Date(info.getValue()).toLocaleString()
      }),
      columnHelper.accessor('info', {
        header: 'Info',
      }),
    ],
    []
  );

  const table = useReactTable({
    data: spots,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="dx-cluster-container">
      <h3>Latest DX Spots from All Clusters</h3>
      {error && <p className="error">{error}</p>}
      {spots.length > 0 ? (
        <>
          <input
            value={table.getState().globalFilter || ''}
            onChange={(e) => table.setGlobalFilter(e.target.value)}
            placeholder="Search DX spots"
            className="dx-cluster-search"
          />
          <div className="table-wrapper">
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          {header.column.getIsSorted()
                            ? header.column.getIsSorted() === 'desc'
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        !error && <p>Loading DX spots...</p>
      )}
    </div>
  );
};

export default DXCluster;
