import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import { ResponsiveLine } from '@nivo/line';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/SensorDataChart.css';

const SensorDataChart = () => {
  const [greenhouseData, setGreenhouseData] = useState([]);
  const [gardenData, setGardenData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchData = async (zone, setData, startDate, endDate) => {
    try {
      const start = startDate.toISOString().split('T')[0];
      const end = endDate.toISOString().split('T')[0];
      const response = await axiosInstance.get(`/api/sensor_data?zone=${zone}&start_date=${start}&end_date=${end}`);
      const transformedData = response.data.map(item => ({
        ...item,
        timestamp: new Date(item.timestamp).toISOString(), // Ensure timestamp is a valid date string
        is_raining: item.is_raining ? 1 : 0,
        relay_status: item.relay_status ? 1 : 0,
      }));
      setData(transformedData);
    } catch (error) {
      console.error(`Error fetching sensor data for ${zone}:`, error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchData('Greenhouse', setGreenhouseData, startDate, endDate);
      await fetchData('Garden', setGardenData, startDate, endDate);
    };

    fetchAllData();
  }, [startDate, endDate]);

  const formatXAxis = tickItem => {
    const date = new Date(tickItem);
    return date.toLocaleString();
  };

  const processDataForNivo = (data, key) => {
    return data.map(item => ({
      x: item.timestamp,
      y: item[key],
    }));
  };

  const commonProperties = {
    margin: { top: 50, right: 160, bottom: 80, left: 60 }, // Increased bottom margin
    xScale: { type: 'time', format: '%Y-%m-%dT%H:%M:%S.%LZ', precision: 'minute' },
    xFormat: 'time:%Y-%m-%d %H:%M',
    axisBottom: {
      format: '%b %d %H:%M',
      tickValues: 'every 2 hours',
      legend: 'Time',
      legendOffset: 50, // Increased legend offset
      legendPosition: 'middle',
    },
    useMesh: true,
    enableSlices: 'x',
    sliceTooltip: ({ slice }) => {
      return (
        <div className="tooltip">
          <div><strong>Time: </strong>{slice.points[0].data.xFormatted}</div>
          {slice.points.map(point => (
            <div key={point.id}>
              <strong>{point.serieId}: </strong>{point.data.yFormatted}
            </div>
          ))}
        </div>
      );
    },
    colors: ['#8884d8', '#82ca9d'],
    legends: [
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
  };

  return (
    <div className="sensor-data-container">
      <h2 className="sensor-data-header">Sensor Data</h2>
      <div className="date-picker-container">
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          className="date-picker"
        />
      </div>

      <div className="chart-container">
        <h3>Soil Moisture</h3>
        <ResponsiveLine
          data={[
            {
              id: 'Greenhouse Soil Moisture',
              data: processDataForNivo(greenhouseData, 'soil_moisture'),
            },
            {
              id: 'Garden Soil Moisture',
              data: processDataForNivo(gardenData, 'soil_moisture'),
            },
          ]}
          {...commonProperties}
          axisLeft={{
            legend: 'Soil Moisture',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Temperature</h3>
        <ResponsiveLine
          data={[
            {
              id: 'Greenhouse Temperature',
              data: processDataForNivo(greenhouseData, 'temperature'),
            },
            {
              id: 'Garden Temperature',
              data: processDataForNivo(gardenData, 'temperature'),
            },
          ]}
          {...commonProperties}
          axisLeft={{
            legend: 'Temperature',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Flow Rate</h3>
        <ResponsiveLine
          data={[
            {
              id: 'Greenhouse Flow Rate',
              data: processDataForNivo(greenhouseData, 'flow_rate'),
            },
            {
              id: 'Garden Flow Rate',
              data: processDataForNivo(gardenData, 'flow_rate'),
            },
          ]}
          {...commonProperties}
          axisLeft={{
            legend: 'Flow Rate',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Is Raining</h3>
        <ResponsiveLine
          data={[
            {
              id: 'Greenhouse Is Raining',
              data: processDataForNivo(greenhouseData, 'is_raining'),
            },
            {
              id: 'Garden Is Raining',
              data: processDataForNivo(gardenData, 'is_raining'),
            },
          ]}
          {...commonProperties}
          axisLeft={{
            legend: 'Is Raining',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Relay Status</h3>
        <ResponsiveLine
          data={[
            {
              id: 'Greenhouse Relay Status',
              data: processDataForNivo(greenhouseData, 'relay_status'),
            },
            {
              id: 'Garden Relay Status',
              data: processDataForNivo(gardenData, 'relay_status'),
            },
          ]}
          {...commonProperties}
          axisLeft={{
            legend: 'Relay Status',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
        />
      </div>
    </div>
  );
};

export default SensorDataChart;
