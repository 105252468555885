import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import './QsoViewer.css';

const QsoViewer = () => {
    const [qsos, setQsos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hiddenColumns, setHiddenColumns] = useState(['id']);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(1000); // Set default number of elements per page to 1000

    const fetchQsos = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('You must be logged in to view QSOs');
            }
    
            const response = await axiosInstance.get('http://lm-electronics.io/api/qsos', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    page,
                    per_page: perPage
                }
            });
    
            setQsos(response.data.qsos);
            setTotalPages(response.data.pages);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [page, perPage]);

    useEffect(() => {
        fetchQsos();
    }, [fetchQsos]);

    const columns = useMemo(
        () => [
            { Header: 'ID', accessor: 'id', width: 100 },
            { Header: 'Call', accessor: 'call', width: 100 },
            { Header: 'QSO Date', accessor: 'qso_date', width: 100 },
            { Header: 'Time On', accessor: 'time_on', width: 100 },
            { Header: 'Band', accessor: 'band', width: 100 },
            { Header: 'Mode', accessor: 'mode', width: 100 },
            { Header: 'RST Sent', accessor: 'rst_sent', width: 100 },
            { Header: 'RST Received', accessor: 'rst_rcvd', width: 100 },
            { Header: 'Frequency', accessor: 'freq', width: 100 },
            { Header: 'Name', accessor: 'name', width: 150 },
            { Header: 'QTH', accessor: 'qth', width: 100 },
            { Header: 'Grid Square', accessor: 'gridsquare', width: 100 },
            { Header: 'Country', accessor: 'country', width: 100 },
            { Header: 'ADIF', accessor: 'adif', width: 100 },
            { Header: 'Continent', accessor: 'continent', width: 100 },
            { Header: 'Latitude', accessor: 'latitude', width: 100 },
            { Header: 'Longitude', accessor: 'longitude', width: 100 },
            { Header: 'CQ Zone', accessor: 'cq_zone', width: 100 },
            { Header: 'ITU Zone', accessor: 'itu_zone', width: 100 }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        allColumns,
        toggleHideColumn,
    } = useTable(
        {
            columns,
            data: qsos,
            initialState: {
                hiddenColumns,
                sortBy: [
                    { id: 'qso_date', desc: true },
                    { id: 'time_on', desc: true }
                ]
            },
        },
        useGlobalFilter,
        useSortBy
    );

    const handleToggleColumn = (columnId) => {
        setHiddenColumns((prevHiddenColumns) => {
            const isHidden = prevHiddenColumns.includes(columnId);
            const newHiddenColumns = isHidden
                ? prevHiddenColumns.filter(id => id !== columnId)
                : [...prevHiddenColumns, columnId];

            toggleHideColumn(columnId, !isHidden);
            return newHiddenColumns;
        });
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setPage(1); // Reset to first page
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="qso-viewer">
            <h1>QSO Records</h1>
            <input
                value={state.globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search all columns"
                style={{ marginBottom: '10px', padding: '8px', fontSize: '16px' }}
            />
            <div className="column-toggle">
                {allColumns.map(column => (
                    <label key={column.id}>
                        <input
                            type="checkbox"
                            checked={!hiddenColumns.includes(column.id)}
                            onChange={() => handleToggleColumn(column.id)}
                        />
                        {column.Header}
                    </label>
                ))}
            </div>
            <div className="table-container">
                <table {...getTableProps()} className="qso-table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id} style={{ width: column.width }}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index} className="tr">
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()} className="td" style={{ width: cell.column.width }}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))} disabled={page === 1}>
                    Previous
                </button>
                <span>Page {page} of {totalPages}</span>
                <button onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages))} disabled={page === totalPages}>
                    Next
                </button>
                <select value={perPage} onChange={handlePerPageChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={1000}>1000</option>
                    <option value={1000}>10000</option>
                </select>
            </div>
        </div>
    );
};

export default QsoViewer;
