import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import SideNav from './components/SideNav';
import Dashboard from './components/Dashboard/Dashboard';
import DXCluster from './components/DXCluster/DXCluster';
import Propagation from './components/Propagation/Propagation';
import AddCluster from './components/AddCluster/AddCluster';
import Login from './components/Login/Login';
import PskReporterViewer from './components/PskReporterViewer/PskReporterViewer';
import AllPskReporterViewer from './components/AllPskReporterViewer/AllPskReporterViewer';
import AdiFileUploader from './components/AdiFileUploader/AdiFileUploader';
import QsoViewer from './components/QsoViewer/QsoViewer';
import Register from './components/Register/Register';
import CustomAppBar from './components/AppBar';
import CallsignsMonitoring from './components/CallsignsMonitoring/CallsignsMonitoring';
import UserSettings from './components/UserSettings/UserSettings'; // Import the UserSettings component
import IrrigationControl from './components/IrrigationControl/IrrigationControl';
import SensorDataChart from './components/SensorDataChart/SensorDataChart';
import './styles/global.css';
import './styles/Login.css';
import './styles/Register.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = '/login';
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);

    const interceptor = axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          window.location.href = '/login';
          return Promise.reject({ message: 'Session expired. Please log in again.' });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <Router>
      <div style={{ display: 'flex', height: '100vh' }}>
        {isAuthenticated && (
          <div style={{ flex: '0 0 20%' }}>
            <SideNav />
          </div>
        )}
        <div style={{ flex: '1 1 80%', padding: '20px', overflowY: 'auto' }}>
          {isAuthenticated && <CustomAppBar onLogout={handleLogout} />}
          <Routes>
            <Route path="/dashboard" element={<PrivateRoute isAuthenticated={isAuthenticated}><Dashboard /></PrivateRoute>} />
            <Route path="/dxcluster" element={<PrivateRoute isAuthenticated={isAuthenticated}><DXCluster /></PrivateRoute>} />
            <Route path="/propagation" element={<PrivateRoute isAuthenticated={isAuthenticated}><Propagation /></PrivateRoute>} />
            <Route path="/addcluster" element={<PrivateRoute isAuthenticated={isAuthenticated}><AddCluster /></PrivateRoute>} />
            <Route path="/pskreporter" element={<PrivateRoute isAuthenticated={isAuthenticated}><PskReporterViewer /></PrivateRoute>} />
            <Route path="/all-pskreporter" element={<PrivateRoute isAuthenticated={isAuthenticated}><AllPskReporterViewer /></PrivateRoute>} />
            <Route path="/upload-adi" element={<PrivateRoute isAuthenticated={isAuthenticated}><AdiFileUploader /></PrivateRoute>} />
            <Route path="/qsos" element={<PrivateRoute isAuthenticated={isAuthenticated}><QsoViewer /></PrivateRoute>} />
            <Route path="/callsign-monitoring" element={<PrivateRoute isAuthenticated={isAuthenticated}><CallsignsMonitoring /></PrivateRoute>} /> 
            <Route path="/user-settings" element={<PrivateRoute isAuthenticated={isAuthenticated}><UserSettings /></PrivateRoute>} /> {/* Add the new route */}
            <Route path="/irrigation-control" element={<PrivateRoute isAuthenticated={isAuthenticated}><IrrigationControl /></PrivateRoute>} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
            <Route path="/sensor-data" element={<PrivateRoute isAuthenticated={isAuthenticated}><SensorDataChart /></PrivateRoute>} />
            <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

const PrivateRoute = ({ children, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default App;
