// src/components/UserSettings/UserSettings.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { Container, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import './UserSettings.css';

const UserSettings = () => {
    const [userData, setUserData] = useState({
        email: '',
        address: '',
        phone: '',
        callsign: '',
        notifyNewDxccGrid: false,
        notifyNewDxccWorld: false,
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('You must be logged in to view user settings.');
                }
                const response = await axiosInstance.get('/api/user', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUserData(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setUserData({
            ...userData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('You must be logged in to update user settings.');
            }
            await axiosInstance.put('/api/user', userData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setSuccess(true);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container className="user-settings">
            <h1>User Settings</h1>
            {success && <div className="success-message">Settings updated successfully!</div>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Address"
                    name="address"
                    value={userData.address}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Phone"
                    name="phone"
                    value={userData.phone}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Callsign"
                    name="callsign"
                    value={userData.callsign}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="notifyNewDxccGrid"
                            checked={userData.notifyNewDxccGrid}
                            onChange={handleInputChange}
                        />
                    }
                    label="Notify for new DXCC in Grid"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="notifyNewDxccWorld"
                            checked={userData.notifyNewDxccWorld}
                            onChange={handleInputChange}
                        />
                    }
                    label="Notify for new DXCC in World"
                />
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                    Update Settings
                </Button>
            </form>
        </Container>
    );
};

export default UserSettings;
