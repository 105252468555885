import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/WeatherForecast.css';

const WeatherForecast = () => {
  const [weatherData, setWeatherData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      setLoading(true);
      try {
        const apiKey = '23e67d8d24864ce9209c0547b7350a41'; // Replace with your OpenWeatherMap API key
        const lat = 50.4156;
        const lon = 4.6924;
        const response = await axios.get(`https://api.openweathermap.org/data/2.5/forecast`, {
          params: {
            lat: lat,
            lon: lon,
            appid: apiKey,
            units: 'metric'
          }
        });
        setWeatherData(response.data.list);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching weather data:', err);
        setError('Failed to fetch weather data');
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  const groupByDay = (data) => {
    return data.reduce((acc, forecast) => {
      const date = new Date(forecast.dt_txt).toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' });
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(forecast);
      return acc;
    }, {});
  };

  const calculateDailyRainProbability = (forecasts) => {
    const totalRainProbability = forecasts.reduce((total, forecast) => total + forecast.pop, 0);
    return (totalRainProbability / forecasts.length) * 100;
  };

  if (loading) {
    return <div>Loading weather data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const groupedData = groupByDay(weatherData);

  return (
    <div className="weather-forecast">
      <h2>5-Day Rain Probability Forecast for Rue grande Pierrere 21B, 5060 Falisolle</h2>
      <div className="forecast-container">
        {Object.keys(groupedData).map((date, index) => (
          <div key={index} className="forecast-day">
            <h3>{date}</h3>
            <p><strong>Daily Rain Probability:</strong> {calculateDailyRainProbability(groupedData[date]).toFixed(2)}%</p>
            <div className="forecast-details">
              {groupedData[date].map((forecast, index) => (
                <div key={index} className="forecast-item">
                  <p>{new Date(forecast.dt_txt).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}</p>
                  <p><strong>Rain Probability:</strong> {forecast.pop * 100}%</p>
                  <p><strong>Temperature:</strong> {forecast.main.temp}°C</p>
                  <p><strong>Weather:</strong> {forecast.weather[0].description}</p>
                  <img src={`http://openweathermap.org/img/wn/${forecast.weather[0].icon}.png`} alt={forecast.weather[0].description} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeatherForecast;
