// src/components/AddCluster/AddCluster.js
import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance';
import './AddCluster.css';

const AddCluster = () => {
  const [name, setName] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('https://lm-electronics.io:5000/api/telnet_clusters', {
        name,
        host,
        port: parseInt(port),
      });
      setMessage(response.data.message);
      setName('');
      setHost('');
      setPort('');
    } catch (error) {
      setMessage('Failed to add cluster');
    }
  };

  return (
    <div className="add-cluster-container">
      <h3>Add Telnet Cluster</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Host</label>
          <input
            type="text"
            value={host}
            onChange={(e) => setHost(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Port</label>
          <input
            type="number"
            value={port}
            onChange={(e) => setPort(e.target.value)}
            required
          />
        </div>
        <button type="submit">Add Cluster</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddCluster;
